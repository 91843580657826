import React from "react";
import DonateForm from "./DonateForm/DonateForm";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Header from "components/App/Header/Header";
import Footer from "components/App/Footer/Footer";
import tw from "twin.macro";
import styled from "styled-components";
import DonatePageImage from "../../images/DonatePageImage.jpg";
import { Col, Container, Row } from "react-bootstrap";

function Donate() {
  //   const Container = tw.div`relative py-2 lg:p-0  mb-6`;
  const Content = tw.div`flex flex-col max-w-screen-xl mx-auto md:pt-10`;
  //   const Row = tw.div`flex flex-col items-center justify-center md:flex-row md:justify-between mb-0`;
  const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0 mt-0`;
  const ImageContainer = styled.div`
    ${tw`h-96 w-100 -mt-20 mb-20  sm:w-full`}
  `;
  return (
    <AnimationRevealPage disabled>
      <Header title="Donate" />
      <Container className="my-3">
        <Row>
          <Col className="d-flex flex-column justify-content-center">
            <img src={DonatePageImage} alt="{About}" />
          </Col>
          <Col>
            <DonateForm />
          </Col>
        </Row>
      </Container>
      <Footer />
    </AnimationRevealPage>
    // <AnimationRevealPage disabled>
    //   <Header title="Donate" />

    //   <Container>
    //     <Content>
    //       <Row>
    //         <Column>
    //           <ImageContainer>
    //             <img src={DonatePageImage} alt="{About}" />
    //           </ImageContainer>
    //         </Column>
    //         <Column>
    //           <DonateForm />
    //         </Column>
    //       </Row>
    //     </Content>
    //   </Container>
    //   <Footer />
    // </AnimationRevealPage>
  );
}

export default Donate;
