import React, { useState } from "react";
import tw from "twin.macro";
import HandPlant from "../../../images/HandPlant.jpg";
import styled from "styled-components";
import { Button, Dropdown } from "react-bootstrap";
import CreditCard from "../../../images/credit-card-50.png";
import Bank from "../../../images/bank.png";
import BackIcon from "../../../images/back.png";

function DonateForm() {
  const [selectedAmount, setSelectedAmount] = useState();
  const [selectedSchedule, setSelectedSchedule] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const [donationType, setDonationType] = useState("Social Venture");

  const Input = tw.input`appearance-none block w-6/12  text-gray-700 border border-secondary-500 rounded py-2 px-4  leading-tight focus:outline-none focus:border-black rounded-md`;
  const PageContainer = tw.div`mx-[4rem] mt-20`;
  const SelectButton = tw.button`rounded-2xl py-px px-6  border border-gray-600 text-sm text-gray-900`;
  const SelectScheduleButton = tw.button`rounded-2xl py-1 px-10  border border-gray-600 text-sm text-gray-900`;

  const SelectButtonOther = tw.input`w-3/12 py-1 rounded-2xl border border-gray-600 text-sm text-gray-900 pl-5 mb-16`;
  const FormRow = tw.div`my-6 flex justify-center gap-x-2`;
  const InputRow = tw.div`ml-16 w-3/4 my-1 flex justify-center gap-x-2`;
  const ContinueButton = tw.button`w-6/12 px-5 py-1 rounded-2xl border border-secondary-500`;
  const Description = tw.p`mt-4 w-full font-medium text-xs leading-relaxed text-black`;
  const ImageContainer = styled.div`
    ${tw`flex justify-center w-100 mt-0 sm:w-full`}
  `;
  const HeadingText = tw.p`text-base font-bold`;
  const AddressTextArea = tw.textarea`border border-secondary-500 px-4 py-2 rounded-md`;
  const IconContainer = tw.span`flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full dark:bg-blue-800 shrink-0 cursor-pointer`;
  const ListItem = tw.li`flex w-full items-center text-blue-600 dark:text-blue-500 after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block dark:after:border-blue-800`;
  const StepList = tw.ol`flex items-center w-2/5 `;
  const StepContainer = tw.ol`flex justify-center  w-full text-blue-600 dark:text-blue-500 mb-2 `;
  const Card = tw.div`border border-gray-500 pt-5 mx-10 shadow-2xl`;
  const Card2 = tw.div`border border-gray-500 pt-5 mx-0 py-10 shadow-2xl`;
  const FormContainer = tw.div`px-0 mx-0 pt-5`;
  const StepThreeContainer = tw.div`px-5 pt-16`;
  const CardBox = tw.div`border border-gray-300 mt-10 flex shadow-md`;
  const CardText = tw.p`pl-3 text-sm py-2 font-semibold`;
  const CardIcon = tw.img`pl-4 h-10`;
  const IconWrapper = tw.div`cursor-pointer`;

  function handleSelectedSchedule(e) {
    setSelectedSchedule(e.target.value);
  }
  function handleSelectedAmount(e) {
    setSelectedAmount(e.target.value);
  }
  function handleStep() {
    setCurrentStep(currentStep + 1);
  }

  const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
  return (
    <PageContainer>
      <Description>
        In a world where cultural heritage often faces the threat of extinction,
        initiatives like DFS (DesiFolksongs) stand as beacons of hope. Through
        DFS, we embark on a journey to safeguard the rich tapestry of South
        Asian cultural art forms. Our mission goes beyond mere preservation; it
        extends to nurturing innovation and critical thinking in the region.
        Your generous donations fuel our efforts to promote scientific content
        and support regional social ventures, creating a dynamic ecosystem where
        tradition and progress converge harmoniously. Join us in our quest to
        empower South Asia, one artwork and idea at a time.
      </Description>

      <FormRow>
        <ImageContainer>
          <img src={HandPlant} alt="{donate}" />
        </ImageContainer>
      </FormRow>
      <Card2>
        <StepContainer>
          <StepList>
            <ListItem>
              <IconContainer
                onClick={() => setCurrentStep(1)}
                style={
                  currentStep === 1
                    ? { backgroundColor: "#0E3868", color: "#ffffff" }
                    : {}
                }
              >
                1
              </IconContainer>
            </ListItem>
            <ListItem>
              <IconContainer
                onClick={() => setCurrentStep(2)}
                style={
                  currentStep === 2
                    ? { backgroundColor: "#0E3868", color: "#ffffff" }
                    : {}
                }
              >
                2
              </IconContainer>
            </ListItem>
            <StepList>
              <IconContainer
                onClick={() => setCurrentStep(3)}
                style={
                  currentStep === 3
                    ? { backgroundColor: "#0E3868", color: "#ffffff" }
                    : {}
                }
              >
                3
              </IconContainer>
            </StepList>
          </StepList>
        </StepContainer>
        {currentStep === 1 && (
          <>
            <FormRow style={{ marginTop: "50px" }}>
              <HeadingText>Choose your donation amount</HeadingText>
            </FormRow>
            <FormRow>
              <Dropdown className=" rounded-5">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="rounded-5 px-5"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#0E3868",
                    borderColor: "#0E3868",
                  }}
                >
                  {donationType}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className=""
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    marginLeft: "0px",
                  }}
                >
                  <Dropdown.Item
                    onClick={() => setDonationType("Social Venture")}
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#0E3868",
                    }}
                  >
                    Social Venture
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setDonationType("Science And Education")}
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#0E3868",
                    }}
                  >
                    Science And Education
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </FormRow>
            <FormRow>
              <SelectScheduleButton
                onClick={handleSelectedSchedule}
                value="Give Once"
                style={{
                  backgroundColor:
                    selectedSchedule === "Give Once" ? "#0E3868" : "#ffffff",
                  color:
                    selectedSchedule === "Give Once" ? "#ffffff" : "#0E3868",
                  borderRadius: "16px",
                }}
              >
                Give Once
              </SelectScheduleButton>
              <SelectScheduleButton
                onClick={handleSelectedSchedule}
                value="Monthly"
                style={{
                  backgroundColor:
                    selectedSchedule === "Monthly" ? "#0E3868" : "#ffffff",
                  color: selectedSchedule === "Monthly" ? "#ffffff" : "#0E3868",
                  borderRadius: "16px",
                }}
              >
                Monthly
              </SelectScheduleButton>
            </FormRow>
            <FormRow>
              <SelectButton
                onClick={handleSelectedAmount}
                value="25"
                style={{
                  backgroundColor:
                    selectedAmount === "25" ? "#0E3868" : "#ffffff",
                  color: selectedAmount === "25" ? "#ffffff" : "#0E3868",
                }}
              >
                $25
              </SelectButton>
              <SelectButton
                onClick={handleSelectedAmount}
                value="50"
                style={{
                  backgroundColor:
                    selectedAmount === "50" ? "#0E3868" : "#ffffff",
                  color: selectedAmount === "50" ? "#ffffff" : "#0E3868",
                }}
              >
                $50
              </SelectButton>
              <SelectButton
                onClick={handleSelectedAmount}
                value="100"
                style={{
                  backgroundColor:
                    selectedAmount === "100" ? "#0E3868" : "#ffffff",
                  color: selectedAmount === "100" ? "#ffffff" : "#0E3868",
                }}
              >
                $100
              </SelectButton>
            </FormRow>
            <FormRow>
              <SelectButtonOther placeholder="Other" />
            </FormRow>
          </>
        )}
        {currentStep === 2 && (
          <FormContainer>
            <InputRow>
              <Input
                id="grid-first-name"
                type="text"
                placeholder="First Name"
                style={{
                  background: "url(/person.png) no-repeat scroll 8px 11px ",
                  paddingLeft: "30px",
                }}
              ></Input>
              <Input
                id="grid-first-name"
                type="text"
                placeholder="Last Name"
                style={{
                  background: "url(/person.png) no-repeat scroll 8px 11px ",
                  paddingLeft: "30px",
                }}
              />
            </InputRow>
            <InputRow>
              <Input
                id="grid-first-name"
                type="text"
                placeholder="Email"
                style={{
                  background: "url(/email.png) no-repeat scroll 8px 11px ",
                  paddingLeft: "30px",
                  width: "100%",
                }}
              />
            </InputRow>
            <InputRow>
              <Input
                id="grid-first-name"
                type="text"
                placeholder="Contact Number"
                style={{
                  background: "url(/phone.png) no-repeat scroll 8px 11px ",
                  paddingLeft: "30px",
                  width: "100%",
                }}
              />
            </InputRow>
            <InputRow>
              <AddressTextArea
                rows="4"
                placeholder="Address"
                type="text"
                cols="50"
                style={{
                  background: "url(/address.png) no-repeat scroll 8px 11px ",
                  paddingLeft: "30px",
                  borderColor: "#0E3868",
                }}
              />
            </InputRow>
            <InputRow>
              <Input
                id="grid-first-name"
                type="text"
                placeholder="Country"
                style={{
                  background: "url(/flag.png) no-repeat scroll 8px 11px ",
                  paddingLeft: "30px",
                }}
              />
              <Input
                id="grid-first-name"
                type="text"
                placeholder="Province"
                style={{
                  background: "url(/flag.png) no-repeat scroll 8px 11px ",
                  paddingLeft: "30px",
                }}
              />
            </InputRow>

            <InputRow>
              <Input
                id="grid-first-name"
                type="text"
                placeholder="Zip/Postal Code"
                style={{
                  background: "url(/address.png) no-repeat scroll 8px 11px ",
                  paddingLeft: "30px",
                }}
              />
              <Input
                id="grid-first-name"
                type="text"
                placeholder="City"
                style={{
                  background: "url(/flag.png) no-repeat scroll 8px 11px ",
                  paddingLeft: "30px",
                }}
              />
            </InputRow>
          </FormContainer>
        )}
        {currentStep === 3 && (
          <StepThreeContainer>
            <HeadingText>Prefered Payment Method</HeadingText>
            <CardBox>
              <CardIcon src={Bank} />
              <CardText>BANK TRANSFER/DEPOSIT</CardText>
            </CardBox>
            <CardBox>
              <CardIcon src={CreditCard} />
              <CardText>DEBIT/CREDIT CARD</CardText>
            </CardBox>
          </StepThreeContainer>
        )}

        <FormRow>
          {(currentStep === 1 || currentStep === 2) && (
            <Button
              onClick={handleStep}
              className="rounded-5 px-5"
              style={{ backgroundColor: "#0E3868" }}
            >
              Continue
            </Button>
          )}
          {currentStep === 3 && (
            <Button
              onClick={() => setCurrentStep(1)}
              className="rounded-5 px-5 mb-5 mt-5"
              style={{ backgroundColor: "#0E3868" }}
            >
              Submit
            </Button>
          )}
        </FormRow>
      </Card2>
    </PageContainer>
  );
}

export default DonateForm;
