import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useSearch } from "../../SongsSearch/SearchContext";
import useAnimatedNavToggler from "../../../helpers/useAnimatedNavToggler.js";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

import { Helmet } from "react-helmet";
import SearchBar from "components/SongsSearch/SearchBar";
import { useNavigate } from "react-router-dom";

const Parent = tw.header`
   shadow fixed top-0 bg-white  w-full z-[999] mx-auto`;

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl  mx-auto  py-3`;
export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mr-12 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-secondary-500 hocus:text-secondary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-teal-500 hocus:text-gray-200 focus:shadow-outline
  border-b-0
  rounded-full bg-teal-500 hover:bg-teal-400
`;

export const LogoLink = styled.a`
  ${tw`flex items-center ml-0!  sm:px-8 xs:px-4`};

  img {
    ${tw`xs:w-40 md:w-41 mr-5`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-secondary-500 transition duration-300 md:px-12 sm:px-8 xs:px-4
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;
export const LinksParent = tw.div`flex items-center`;
export const DonateButton = tw.button`mr-16 bg-secondary-1000 text-white px-8 py-1 rounded-3xl`;
export const DonateButtonMobile = tw.button` bg-secondary-1000 text-white px-8 py-1 rounded-3xl`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
  title,
}) => {
  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this Header to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */
  const defaultLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Home</NavLink>
      <NavLink href="/about">About</NavLink>
      <NavLink href="/contact">Contact Us</NavLink>
    </NavLinks>,
    // <NavLinks key={2}>
    //   <PrimaryLink href="/#">Contact Us</PrimaryLink>
    // </NavLinks>,
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const { setSearchTerm } = useSearch();

  const handleSearch = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  };
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink img href="/">
      <img src="/logo.png" alt="logo" />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  let navigate = useNavigate(); // Hook for navigation

  return (
    <Parent>
      <Header className={className || "header-light"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"Desi Folk Songs - " + title || ""}</title>
        </Helmet>

        <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
          {logoLink}

          <LinksParent>
            {links}

            <SearchBar onSearch={handleSearch} />
            <DonateButton onClick={() => navigate("/donate")}>
              Donate
            </DonateButton>
          </LinksParent>
        </DesktopNavLinks>
        <MobileNavLinksContainer
          css={collapseBreakpointCss.mobileNavLinksContainer}
        >
          {logoLink}

          <MobileNavLinks
            initial={{ x: "150%", display: "none" }}
            animate={animation}
            css={collapseBreakpointCss.mobileNavLinks}
          >
            {links}
            <NavLinks>
              <NavLink>
                {" "}
                <SearchBar onSearch={handleSearch} />
              </NavLink>
              <DonateButtonMobile onClick={() => navigate("/donate")}>
                Donate
              </DonateButtonMobile>
            </NavLinks>
          </MobileNavLinks>
          <NavToggle
            onClick={toggleNavbar}
            className={showNavLinks ? "open" : "closed"}
          >
            {showNavLinks ? (
              <CloseIcon tw="w-6 h-6" />
            ) : (
              <MenuIcon tw="w-6 h-6" />
            )}
          </NavToggle>
        </MobileNavLinksContainer>
      </Header>
    </Parent>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
