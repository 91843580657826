import Header from "components/App/Header/Header";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import "./Contact.css";
import { useForm } from "react-hook-form";
import ContactFooter from "./ContactFooter/ContactFooter";

const statuses = {
  idle: "idle",
  pending: "pending",
  success: "success",
  error: "error",
};

function Contact() {
  // react hook form
  const { register, handleSubmit, reset } = useForm();
  // statuses: idle | pending | success | error
  const [submitStatus, setSubmitStatus] = useState(statuses.idle);

  const onSubmit = (formData) => {
    setSubmitStatus(statuses.success);
  };
  return (
    <>
      <AnimationRevealPage disabled>
        <Header title="Contact" />
        <Container
          style={{
            paddingLeft: "60px",
            paddingTop: "90px",
          }}
        >
          <Row>
            <p className="fs-2 mb-3 fw-semibold">Contact Us</p>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3 w-50 " controlId="firstname">
                  <Form.Control
                    type="text"
                    className="rounded-5"
                    placeholder="First Name"
                    required
                    {...register("firstName")}
                  />
                </Form.Group>
                <Form.Group className="mb-3 w-50" controlId="lastname">
                  <Form.Control
                    type="text"
                    className="rounded-5"
                    placeholder="Last Name"
                    {...register("lastName")}
                  />
                </Form.Group>
                <Form.Group className="mb-3 w-50" controlId="email">
                  <Form.Control
                    type="text"
                    className="rounded-5"
                    placeholder="Email"
                    required
                    {...register("email")}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="message">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    className="rounded-3"
                    placeholder="Enter your message"
                    required
                    {...register("message")}
                  />
                </Form.Group>

                <Button
                  type="submit"
                  className="submit-button rounded-5 px-4"
                  //disabled={submitStatus === statuses.pending}
                >
                  Submit
                </Button>
              </Form>
              {submitStatus === statuses.success && (
                <Alert
                  variant="success"
                  onClose={() => setSubmitStatus(statuses.idle)}
                  dismissible
                  className="mt-3"
                >
                  Thank you, the form has been successfully submitted.
                </Alert>
              )}
            </Col>
            <Col></Col>
          </Row>
        </Container>
        <ContactFooter />
      </AnimationRevealPage>
    </>
  );
}

export default Contact;
