import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import LinkedInImage from "../../../images/linkedIn-logo.png";

import "./ContactFooter.css";

const Footer = () => {
  return (
    <div className="footer mt-3">
      <div className="footer-overlay">
        <Container>
          <Row>
            <Col className="text-start ">
              <h2 className="get-in-touch-heading ">GET IN TOUCH</h2>
            </Col>
          </Row>
          <Row>
            <Col className="text-start ">
              <p className="get-in-touch-text">
                Hey! Looking forward to start connect with you
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="text-start ">
              <a
                href="https://www.linkedin.com/company/pixeledge/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={LinkedInImage}
                  alt="linkedIn"
                  className="linkedin-logo"
                />
              </a>
            </Col>
          </Row>

          <Row className="bottom-footer">
            <Col className="text-center ">
              <p className="overlay-bottom-text">
                © Copyright 2024, Desi Folk Songs All Rights Reserved.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
