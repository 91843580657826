import React, { useState, useEffect, useRef } from "react";
import { Container, ContentWithPaddingSm } from "shared/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { useSearch } from "../../SongsSearch/SearchContext";
import { PrimaryButton as PrimaryButtonBase } from "shared/Buttons";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

import { Link } from "react-router-dom";

import { get } from "utils/DeApi";
import Slider from "components/Slider/Slider";

const Songs = tw.div`mt-0 mb-20 flex flex-wrap mx-5`;
const SongContainer = styled.div`
  ${tw`w-full h-80 sm:w-1/2 lg:w-1/4 sm:p-4`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-1/3 sm:rounded-t-none sm:rounded-l-lg group-hover:visible`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`flex flex-col lg:-my-4 w-full rounded-lg h-80 `;
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
      background-size: 100% 100%;
    `}
  ${tw`h-40 bg-center rounded-t-lg`}
`;
const Info = tw.div`p-4 border-2 border-t-0 rounded-lg rounded-t-none bg-gray-100 hover:shadow-lg`;
const Title = tw.div`font-bold text-lg text-start text-secondary-1000 transition duration-300`;
const Description = tw.div`text-sm lg:text-base font-normal`;
const Button = tw.button`flex justify-start items-center text-center py-1 mt-1 font-bold rounded border-2 border-secondary-1000 focus:shadow-outline focus:outline-none transition duration-300`;
const NoSongs = tw.h1`w-2/4 mx-auto my-12 text-center p-10 bg-gray-100 shadow-lg font-bold text-xl text-secondary-1000`;
const ViewLink = tw.span`text-xs underline mx-1 font-bold float-right mt-1 content-center`;
const PenIcon = tw.img`w-[30px]`;
const PlayIcon = tw.img`w-[40px] animate-pulse `;
const HoverText = tw.button` group-hover:visible`;
const Tooltip = styled.div(() => [
  tw`absolute bg-white text-secondary-1000 hidden w-72 p-4  rounded-lg border-2 border-secondary-1000  bg-opacity-75`,
  css`
    ${HoverText}:hover & {
      ${tw`block `}
    }
  `,
]);
const PlayButton = styled.div(() => [
  tw`absolute bg-white text-secondary-1000 hidden w-12   bg-opacity-0 relative h-auto mx-auto my-auto  inset-y-1/2`,
  css`
    ${Image}:hover & {
      ${tw`block `}
    }
  `,
]);
const InfoValue = tw.span`text-sm col-span-2`;
const InfoContainer = tw.div`flex items-center mt-1 -ml-2`;
const InfoText = tw.span` text-sm `;
const InfoIconMic = tw.img`w-[25px] hover:animate-pulse `;
const InfoIconFeather = tw.img`w-[25px] hover:animate-pulse pl-1`;
const SliderContainer = tw.div`mt-4`;
export default () => {
  const [songsData, setSongsData] = useState();
  const subscribedPromises = useRef([]);
  const { searchTerm } = useSearch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchSongs = () => {
      setError(null);
      setIsLoading(true);

      const contentPromise = get(`contents`, {
        params: { offset: 0, limit: 500 },
      });

      contentPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);

          const filteredSongs = response.data.filter((song) =>
            song.title.toLowerCase().includes(searchTerm.toLowerCase())
          );
          setSongsData(filteredSongs);
        })

        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(contentPromise);
    };
    fetchSongs();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [searchTerm]);
  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  const getMetaData = (song, tag) => {
    const filterData = song?.metadata?.find((data) => data.key === tag)?.value;
    return filterData;
  };
  return (
    <Container>
      <SliderContainer>
        <Slider
          images={[
            "desi-folk-songs-banner-temp.jpg",
            "south asian banner.png",
            "golden memories banner.png",
            "sufi banner.png",
          ]}
        />
      </SliderContainer>
      <ContentWithPaddingSm>
        <Songs>
          {songsData?.length === 0 ? (
            <NoSongs>No Songs Found</NoSongs>
          ) : (
            songsData
              ?.filter((item) => item.categories[0].categoryName === "song")
              .map((song, index) => (
                <SongContainer key={index}>
                  <Post className="group">
                    <Link to={`/song/${song.contentId}`}>
                      <Image imageSrc={song.contentThumbnail} className="group">
                        <PlayButton>
                          <PlayIcon src={"play-button.png"} />
                        </PlayButton>
                      </Image>
                    </Link>
                    <Info>
                      <HoverText className="group">
                        <Link to={`/song/${song.contentId}`}>
                          <Title
                            title={song.title.length > 15 ? song.title : null}
                          >
                            {song.title.length > 15
                              ? song.title.substring(0, 15) + "..  "
                              : song.title + "  "}

                            <InfoValue>
                              {getMetaData(song, "Released") !== undefined
                                ? getMetaData(song, "Released")?.length > 10
                                  ? "(" +
                                    getMetaData(song, "Released").substring(
                                      0,
                                      10
                                    ) +
                                    "..)"
                                  : "(" + getMetaData(song, "Released") + ")" ||
                                    ""
                                : ""}
                            </InfoValue>
                          </Title>
                        </Link>
                        <Tooltip>
                          <Description>
                            {song?.description?.length > 150
                              ? song.description.substring(0, 150) + "..."
                              : song.description}

                            <br />
                            <Link to={`/song/${song.contentId}`}>
                              <ViewLink>View Full</ViewLink>
                            </Link>
                          </Description>
                        </Tooltip>
                      </HoverText>
                      <InfoContainer>
                        <InfoIconFeather
                          src={"1st pen.png"}
                          title="Poet"
                        ></InfoIconFeather>
                        <InfoText>
                          {" "}
                          {getMetaData(song, "Poet")?.length > 25
                            ? getMetaData(song, "Poet").substring(0, 25) + ".."
                            : getMetaData(song, "Poet") || "N/A"}{" "}
                        </InfoText>
                      </InfoContainer>
                      <InfoContainer>
                        <InfoIconMic
                          src={"1st mike.png"}
                          title="Singer"
                        ></InfoIconMic>
                        <InfoText>
                          {getMetaData(song, "Singer")?.length > 25
                            ? getMetaData(song, "Singer").substring(0, 25) +
                              ".."
                            : getMetaData(song, "Singer") || "N/A"}
                        </InfoText>
                      </InfoContainer>
                      {/* <InfoColumns>
                        <InfoValue>
                          {getMetaData(song, "Poet")?.length > 25
                            ? getMetaData(song, "Poet").substring(0, 25) + ".."
                            : getMetaData(song, "Poet") || "N/A"}
                        </InfoValue>
                        <InfoValue>
                          {getMetaData(song, "Singer")?.length > 25
                            ? getMetaData(song, "Singer").substring(0, 25) +
                              ".."
                            : getMetaData(song, "Singer") || "N/A"}
                        </InfoValue>
                      </InfoColumns> */}
                      {/* <Button as={Link} to={`/song/${song.contentId}`}>
                        <PenIcon src={"Cardlogo.png"} />
                        <Link to={`/song/${song.contentId}`}>
                          Read Interpretation
                        </Link>
                        <Link to={`/song/${song.contentId}`}>
                        <ViewLink>View Full</ViewLink>
                          </Link> 
                      </Button> */}
                      {/* <Description>
                      {song?.description?.length > 50
                        ? song.description.substring(0, 50) + "..."
                        : song.description}
                      <Link to={`/song/${song.contentId}`}>
                        <ViewLink>View Full</ViewLink>
                      </Link>
                    </Description> */}
                    </Info>
                  </Post>
                </SongContainer>
              ))
          )}
        </Songs>
      </ContentWithPaddingSm>
    </Container>
  );
};
