import { useEffect } from "react";
import styled, { css } from "styled-components";
import tw from "twin.macro";

const LyricsContainer = tw.div`md:py-20 xs:py-10 sm:py-12 lg:py-10 w-full justify-center flex mx-auto sm:w-1/2 md:w-1/3 lg:w-2/4 sm:pr-10 md:pr-6 lg:pr-12`;

const OriginalLyrics = tw.div`font-urdu! text-black text-center lg:text-xl! md:text-sm! text-sm!`;

export default ({ lyrics, songLanguage }) => {
  //To remove inline styling
  function RemoveStyle(all) {
    var i = all.length;
    var j, is_hidden;

    // Presentational attributes.
    var attr = [
      "align",
      "background",
      "bgcolor",
      "border",
      "cellpadding",
      "cellspacing",
      "color",
      "face",
      "height",
      "hspace",
      "marginheight",
      "marginwidth",
      "noshade",
      "nowrap",
      "valign",
      "vspace",
      "width",
      "vlink",
      "alink",
      "text",
      "link",
      "frame",
      "frameborder",
      "clear",
      "scrolling",
      "style",
    ];

    var attr_len = attr.length;

    while (i--) {
      is_hidden = all[i].style.display === "none";

      j = attr_len;

      while (j--) {
        all[i].removeAttribute(attr[j]);
      }

      // Re-hide display:none elements,
      // so they can be toggled via JS.
      if (is_hidden) {
        all[i].style.display = "none";
        is_hidden = false;
      }
    }
  }

  useEffect(() => {
    var all = document.getElementsByTagName("span");
    RemoveStyle(all);
  }, []);

  return (
    <>
      <LyricsContainer>
        {lyrics ? (
          <OriginalLyrics>
            {songLanguage !== "Both" ? (
              lyrics?.split("\n")?.map((data, index) => (
                <div key={index}>
                  <p>{data}</p>
                  {data === "" && <br />}
                </div>
              ))
            ) : (
              <div dangerouslySetInnerHTML={{ __html: lyrics }} />
            )}
          </OriginalLyrics>
        ) : (
          <h2>No Lyrics Found.!</h2>
        )}
      </LyricsContainer>
    </>
  );
};
