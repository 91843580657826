import React from "react";
import tw from "twin.macro";

//const DescriptionContainer = tw.div`md:py-20 xs:py-10 sm:py-12 lg:py-10 w-full justify-center flex mx-auto sm:w-1/2 md:w-1/3 lg:w-2/4 sm:pr-10 md:pr-6 lg:pr-12`;
const DescriptionContainer = tw.div`md:py-20 xs:py-20 sm:py-12 lg:py-5 w-full justify-center flex mx-auto lg:pr-20`;

const Description = tw.div`text-center mt-0 lg:text-base! md:text-base! text-sm! font-inria mb-20`;

export default ({ description }) => {
  return (
    <div>
      <DescriptionContainer>
        {description ? (
          <Description>
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </Description>
        ) : (
          <h2>Poet description not found.!</h2>
        )}
      </DescriptionContainer>
    </div>
  );
};
